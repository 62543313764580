import React, { useEffect } from 'react'
// import axios from "axios";
// import { client as ipfsClient } from "ipfs-http-client"

import { ethers } from 'ethers'
import firebase from 'firebase'
// INTERNAL IMPORTS
import { NFTMarketplaceAddress, NFTMarketplaceABI } from './constants'
import { useStore } from '../modules/store'
import { notify } from '../modules/notify'
import { auth, firestore, AnonymousSignIn } from '../modules/firebase'

import { purge } from '../utils/persist'
import { fetchNetworkDetails } from '../utils/utils'

// -- ETHEREUM CONNECTION CONFIG
const { ethereum } = window
const isMetamaskInstalled = ethereum && ethereum.isMetaMask
let provider = ethereum ? new ethers.providers.Web3Provider(ethereum) : null
let signer = provider?.getSigner()

// -- IPFS CONNECTION CONFIG
const ipfsClient = require('ipfs-http-client')
// const ipfs = ipfsClient.create(process.env.REACT_APP_INFURA_IPFS_ENDPOINT);

const infura_project_id = process.env.REACT_APP_INFURA_PROJECT_ID
const infura_secret = process.env.REACT_APP_INFURA_API_KEY_SECTRET
const infura_auth =
  'Basic ' +
  Buffer.from(infura_project_id + ':' + infura_secret).toString('base64')
const infura_subdomain = process.env.REACT_APP_INFURA_IPFS_SUBDOMAIN

const ipfs_client = ipfsClient.create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: infura_auth,
  },
})

// CONTRACT CONFIGS

const checkConnection = async () => {
  if (!isMetamaskInstalled) {
    const errMsg = 'Please install metamask extension'
    throw new Error(errMsg)
  }

  return (
    ethereum
      .request({ method: 'eth_accounts' })
      .then(handleAccountsChanged)
      // .catch(console.log("Something went wrong while checking for connection."));
      .catch((err) => {
        throw new Error(err.message)
      })
  )
}

const authenticate = async () => {
  try {
    const accounts = await provider.send('eth_requestAccounts', [])
    return handleAccountsChanged(accounts)
  } catch (e) {
    let errMsg = null
    // eslint-disable-next-line
    if (e.code == -32002) {
      errMsg = `Please click on the Metamask Popup icon to focus on your wallet.`
      // notify(errMsg, "INFO");
      throw new Error(errMsg)
    } else {
      errMsg = `Auth Error: Something went wrong.`
      // notify(errMsg, "ERROR")
      throw new Error(errMsg)
    }
  }
}

const handleChainChanged = async () => {
  const InvalidNetworkErrorMsg = `Please change the newtork to ${
    fetchNetworkDetails(Number(process.env.REACT_APP_NETWORK_ID)).name
  }`

  const { chainId } = await provider.getNetwork()
  if (Number(process.env.REACT_APP_NETWORK_ID) !== chainId) {
    // notify(InvalidNetworkErrorMsg, "INFO")
    throw new Error(InvalidNetworkErrorMsg)
  } else {
    return chainId
  }
}

const handleAccountsChanged = async (accounts) => {
  const { chainId } = await handleChainChanged()

  provider = new ethers.providers.Web3Provider(ethereum)
  signer = provider?.getSigner()

  if (!accounts || !accounts.length) return authenticate()

  return {
    accounts,
    signer,
    chainId,
  }
}

// -- UPLOAD TO IPFS FUNCTIONS

const generateMetadata = ({ index, dna, position }) => {
  const coor = position.split(',')
  return {
    dna: `${dna}`,
    name: `Parcel #${index}`,
    external_ur: `${process.env.REACT_APP_DOMAIN}`,
    description:
      'Million Token Website is a collection of 1,618,000 parcels on the Ethereum Network. ',
    image: `${process.env.REACT_APP_DOMAIN}/images/pixel-preview.png`, // FUTURE MAKE IT DYNAMIC TO SERVER URL
    edition: 1,
    date: Date.now(),
    attributes: [
      {
        trait_type: 'positionX',
        display_type: 'Position X',
        value: coor[0],
      },
      {
        trait_type: 'positionY',
        display_type: 'Position Y',
        value: coor[1],
      },
    ],
    compiler: 'MillionToken Art Engine',
  }
}

const uploadToIPFS = async (metadata_to_upload) => {
  try {
    // const urls = [];
    // for (let i = 0; i < metadata_to_upload.length; i++) {

    //     const fileAdded = await ipfs_client.add(JSON.stringify(metadata_to_upload[i]));
    //     const url = `${infura_subdomain}/ipfs/${fileAdded.path}`;
    //     // const url = `https://infura-ipfs.io/ipfs/${fileAdded.path}`;
    //     //sample response "https://infura-ipfs.io/ipfs/QmUpuZjxt7ZEPQe5zoiLvDVfZfQThyQpukqqxe17c5MzqB";

    //     // // IMPORTANT  - RESET CONFIG FOR PRODUCTION
    //     // const url = "https://infura-ipfs.io/ipfs/QmUpuZjxt7ZEPQe5zoiLvDVfZfQThyQpukqqxe17c5MzqB";
    //     urls.push(url);
    // }
    // return urls;

    const fileAdded = await ipfs_client.add(JSON.stringify(metadata_to_upload))
    const url = `${infura_subdomain}/ipfs/${fileAdded.path}`
    return url
  } catch (e) {
    console.error(e)
    // throw new Error(e.message);
    throw new Error('Something went wrong when updloading the file.')
  }
}

// -- FETCH SMART CONTRACT

const fetchContract = async () =>
  new ethers.Contract(
    NFTMarketplaceAddress,
    NFTMarketplaceABI,
    (signer = provider?.getSigner())
  )

const connectWithSmartContract = async () => {
  try {
    await handleChainChanged()
    const contract = await fetchContract()
    return contract
  } catch (err) {
    console.error(err)
    throw new Error(err.message)
  }
}

//  ---- FETCH NFS  ------//

const checkAvailability = async (_status, _tokenData) => {
  try {
    const contract = await connectWithSmartContract()
    const checkTokenAvailability = await contract.checkTokenAvailability(
      _status,
      _tokenData
    )
    return checkTokenAvailability
  } catch (e) {
    console.error(e)
    // let errMsg = "";
    // errMsg = "Something went wrong while cheching for availaibilty."
    throw new Error(e.message)
  }
}
const fetchMyParcels = async () => {
  try {
    const contract = await connectWithSmartContract()
    let resp = (await contract.fetchMyTokens()) || []

    if (resp.length) {
      const data = await resp.map(
        ({
          tokenId,
          index,
          position,
          owner,
          seller,
          price,
          onAuction,
          sold,
        }) => {
          return {
            tokenId: Number(ethers.utils.formatUnits(tokenId, 0)),
            index: Number(ethers.utils.formatUnits(index, 0)),
            owner,
            seller,
            position,
            price: ethers.utils.formatEther(price),
            onAuction,
            sold,
          }
        }
      )
      resp = data
    }
    return resp
  } catch (e) {
    console.error(e)
    let errMsg = ''
    errMsg = 'Something went wrong while fetching NFTS.'
    throw new Error(errMsg)
  }
}
const fetchListedTokens = async () => {
  try {
    const contract = await connectWithSmartContract()
    let resp = (await contract.fetchListedTokens()) || []

    if (resp.length) {
      const data = await resp.map(
        ({
          tokenId,
          index,
          position,
          owner,
          seller,
          price,
          onAuction,
          sold,
        }) => {
          return {
            tokenId: Number(ethers.utils.formatUnits(tokenId, 0)),
            index: Number(ethers.utils.formatUnits(index, 0)),
            owner,
            seller,
            position,
            price: ethers.utils.formatEther(price),
            onAuction,
            sold,
          }
        }
      )

      resp = data
    }
    return resp
  } catch (e) {
    console.error(e)
    let errMsg = ''
    errMsg = 'Something went wrong while fetching NFTS.'
    throw new Error(errMsg)
  }
}
const fetchAllTokens = async () => {
  try {
    const contract = await connectWithSmartContract()
    let resp = (await contract.fetchAllTokens()) || []

    if (resp.length) {
      const data = await resp.map(
        ({
          tokenId,
          index,
          position,
          owner,
          seller,
          price,
          onAuction,
          sold,
        }) => {
          return {
            tokenId: Number(ethers.utils.formatUnits(tokenId, 0)),
            index: Number(ethers.utils.formatUnits(index, 0)),
            owner,
            seller,
            position,
            price: ethers.utils.formatEther(price),
            onAuction,
            sold,
          }
        }
      )

      resp = data
    }
    return resp
  } catch (e) {
    console.error(e)
    let errMsg = ''
    errMsg = 'Something went wrong while fetching NFTS.'
    throw new Error(errMsg)
  }
}

// const fetchNFTS = async () => {
//     try {

//         const contract = await connectWithSmartContract();
//         const data = await contract.fetchMarketItem() || [];

//         let parcels = [];
//         if (data && data.length) {
//             parcels = await Promise.all(
//                 data.map(async ({ tokenId, seller, owner, price: unformattedPrice }) => {
//                     const tokenURI = await contract.tokenURI(tokenId);
//                     const { data: { image, name, description, dna, attributes } } = await axios.get(tokenURI);
//                     const price = ethers.utils.formatUnits(
//                         unformattedPrice.toString(),
//                         "ether"
//                     );

//                     return {
//                         tokenId: tokenId.toNumber(),
//                         dna,
//                         price,
//                         tokenURI,
//                         seller,
//                         owner,
//                         image,
//                         name,
//                         description,
//                         attributes
//                     }
//                 })
//             );
//         }

//         return parcels;

//     } catch (e) {

//         console.error(e);
//         let errMsg = "";
//         errMsg = "Something went wrong while fetching NFTS."
//         throw new Error(errMsg);
//     }
// }

export const NFTMarketplaceContext = React.createContext()

export const NFTMarketplaceProvider = ({ children }) => {
  const fireAccountsRef = firestore.collection('accounts')
  const parcelsRef = firestore.collection('parcels')

  const purchasesRef = firestore.collection('purchases')

  const {
    setAuthenticating,
    accounts,
    setAccounts,
    setBalance,
    setSigner,

    // mintModalData,
    setMintModalData,
    // minting,
    setMinting,

    // total,
    // setTotal,

    setFireUser,
    fireAccount,
    setFireAccount,
    // fetchMyNFTS,

    selectedParcel,

    // mapIsMultipleSelect,
    // multipleSelectedParcel,
  } = useStore()

  useEffect(() => {
    if (isMetamaskInstalled) {
      checkConnection()
      ethereum.on('chainChanged', (_chainId) => {
        handleChainChanged()
        window.location.reload()
      })
      ethereum.on('accountsChanged', (accs) => {
        setAccounts(accs)
        fetchAccBalance()
        handleAccountsChanged(accs)
        window.location.reload()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //  -- BLOCKCHAIN AUTH CONNECTIONS FUNCTIONS

  const fetchAccBalance = async () => {
    const fetchBal = await provider.getBalance(accounts[0])
    const accBal = ethers.utils.formatEther(fetchBal)
    setBalance(Number(accBal).toFixed(4))
    return accBal
  }
  // const checkIfConnected = async () => {
  //     setAuthenticating(true);
  //     try {
  //         const _ethAcc = await checkConnection();
  //         if (_ethAcc.accounts && _ethAcc.signer) {
  //             setAccounts(_ethAcc.accounts);
  //             setSigner(_ethAcc.signer);
  //         }
  //         setAuthenticating(false);
  //     } catch (e) {
  //         setAuthenticating(false);
  //         console.log(e);
  //     }
  // }

  const connectWallet = async () => {
    setAuthenticating(true)
    try {
      const { accounts, signer } = await authenticate()
      setAccounts(accounts)
      setSigner(signer)

      setAuthenticating(false)
      notify('Successfully logged in.', 'INFO')
    } catch (e) {
      setAuthenticating(false)
      notify(e.message, 'ERROR')
    }
  }

  const signOut = async () => {
    setAuthenticating(true)
    try {
      await purge()
      localStorage.removeItem('root')
      setTimeout(() => {
        setAccounts([])
        setSigner([])
        setFireUser([])
        notify('Successfully logged out.', 'INFO')
        setAuthenticating(false)
      }, 500)
    } catch (e) {
      setAuthenticating(false)
      notify(e.message, 'ERROR')
    }
  }

  //  -- FIREBASE AUTH FUNCTIONS
  useEffect(() => {
    if (!auth || !auth.currentUser) return anonSignUp()
    if (!fireAccount || !fireAccount.length) fetchAnonAccount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser])

  const anonSignUp = async () => {
    try {
      await AnonymousSignIn()
        .then((result) => {
          setFireUser(result.user)
        })
        .catch((error) => {
          setAuthenticating(false)
          console.log(`ANON SIGNUP ERROR::`, error)
        })
    } catch (e) {
      const errMsg = 'Something went wromg while authentication on firebase.'
      console.error(e)
      throw new Error(errMsg)
    }
  }

  const fetchAnonAccount = async () => {
    try {
      const fetchInstance = await fireAccountsRef.doc(accounts[0]).get()

      if (fetchInstance.exists) {
        const fdata = fetchInstance.data()
        setFireAccount(fdata)
      } else {
        createDbAcc()
      }
    } catch (error) {
      console.log('ERROR for retching account user')
      console.log(error)
    }
  }

  const createDbAcc = async () => {
    if (!accounts[0] || !auth.currentUser)
      throw new Error('ERROR: CreatingDBAcc invalid parameters.')

    const toSend = {
      accounts: accounts,
      uid: auth.currentUser?.uid,
      updated_at: firebase.firestore.Timestamp.now(),
      created_at: firebase.firestore.Timestamp.now(),
    }
    await fireAccountsRef.doc(accounts[0]).set({
      ...toSend,
    })
    return fetchAnonAccount()
  }

  //  -- CONTRACT FUNCTIONS

  const checkContract = async () => {
    const contract = await connectWithSmartContract()
    return contract
  }

  // const fetchMetadata = async () => {
  //     const data = map(selectedParcel, (data, index) => {

  //         setMintModalData({
  //             status: "LOADING",
  //             title: "Loading.",
  //             message: `Fetching ${index} \\ ${data.length} of the metadata.`
  //         });

  //         return generateMetadata(data);
  //     });

  //     return data;
  // }

  // const checkParcelsAvailability = async () => {
  //     const contract = await connectWithSmartContract();
  //     if (contract) {
  //         const _tokenIds = selectedParcel.map(data => (data.index));
  //         const resp = await contract.checkTokenAvailability(_tokenIds);
  //         return resp
  //     }
  // }

  const parseEtherToString = (value) => {
    // return ethers.utils.toString(amt);
    return ethers.utils.formatEther(value)
  }
  const parseStingToEther = (value) => {
    // return ethers.utils.toString(amt);
    return ethers.utils.parseEther(`${value}`)
  }

  // const getSum = (total, token) => {
  //     return total + token.price;
  // }
  const genNewMetadata = async () => {
    try {
      const _metadata = []
      const _genData = []

      for (let ind = 0; ind < selectedParcel.length; ind++) {
        const data = selectedParcel[ind]
        setMintModalData({
          status: 'MINTING',
          title: 'Minting',
          message: `Fetching: ${ind + 1} \\ ${
            selectedParcel.length
          } of the metadata.`,
        })

        const fireParcel = await parcelsRef.doc(data.position).get()
        let _tokenData = null

        if (fireParcel.exists) {
          const fireParcelData = fireParcel.data()
          _tokenData = {
            tokenId: fireParcelData.tokenId,
            tokenURI: fireParcelData.tokenURI,
            price: ethers.utils.parseEther(`${fireParcelData.price}`),
            index: fireParcelData.index,
            position: fireParcelData.position,
          }

          _metadata.push(fireParcelData.metadata)
        } else {
          setMintModalData({
            status: 'MINTING',
            title: 'Minting',
            message: `Processing: ${ind + 1} \\ ${
              selectedParcel.length
            } of the metadata.`,
          })
          const metadata = generateMetadata(data)
          _metadata.push(metadata)

          setMintModalData({
            status: 'MINTING',
            title: 'Minting',
            message: `Uploading: ${ind + 1} \\ ${
              selectedParcel.length
            } of the metadata.`,
          })
          const tokenURI = await uploadToIPFS(metadata)
          const price = ethers.utils.parseEther(`${data.price}`)

          _tokenData = {
            tokenId: ind, // This input can be any uint256 on creation.
            tokenURI,
            price,
            index: data.index,
            position: data.position,
          }
        }

        _genData.push(_tokenData)
      }

      return { _genData, _metadata }
    } catch (e) {
      console.error(e)
      throw new Error(e.message)
    }
  }
  const createMarketPlaceSale = async (_status) => {
    try {
      const contract = await connectWithSmartContract()

      if (contract) {
        const listingPrice = await contract.getListingPrice()
        const gasPrice = await provider.getGasPrice()

        const _total =
          ethers.utils.formatEther(listingPrice) * selectedParcel.length
        const value = ethers.utils.parseEther(`${_total}`)
        const nonce = await provider.getTransactionCount(contract.address)
        setMinting(true)

        const _price = ethers.utils.formatEther(listingPrice)
        if (_status === 'MINT') {
          setMintModalData({
            status: 'MINTING',
            title: 'Minting',
            message: 'Fetching Parcels: Please wait as we fetching metadata.',
          })

          const { _genData, _metadata } = await genNewMetadata()

          setMintModalData({
            status: 'MINTING',
            title: 'Minting.',
            message: `Please sign to complete the Mint Transaction.`,
          })

          const gasLimit = await contract.estimateGas.marketPlaceSale(
            _status,
            _genData,
            { value, nonce }
          )

          const createSaleTx = await contract.marketPlaceSale(
            _status,
            _genData,
            {
              gasLimit,
              gasPrice,
              value,
              nonce,
            }
          )

          setMintModalData({
            status: 'MINTING',
            title: 'Minting.',
            message:
              'The transaction has been sent and waiting for confirmation.',
          })
          const tx = await createSaleTx.wait()
          if (tx && tx.status === 1) {
            const purchaseData = {
              owner: accounts[0],
              created_at: firebase.firestore.Timestamp.now(),
            }

            const parcels = []

            for (let i = 0; i < _genData.length; i++) {
              setMintModalData({
                status: 'MINTING',
                title: 'Updating Database',
                message: `Updating: ${i + 1} \\ ${
                  selectedParcel.length
                } of NFT's, please wait.`,
              })

              parcels.push(_genData[i].position)

              const toSend = {
                ..._genData[i],
                owner: accounts[0],
                onAuction: false,
                price: _price,
                metadata: _metadata[i],
                updated_at: firebase.firestore.Timestamp.now(),
                created_at: firebase.firestore.Timestamp.now(),
              }

              await parcelsRef.doc(_genData[i].position).set(
                {
                  ...toSend,
                },
                { merge: true }
              )
            }

            purchaseData['parcels'] = parcels

            await purchasesRef.doc(_genData[0].position).set(
              {
                ...purchaseData,
              },
              { merge: true }
            )
          }

          return tx
        } else if (_status === 'PURCHASE') {
          // const _total = selectedParcel.reduce(getSum, 0)

          setMintModalData({
            status: 'PURCHASE',
            title: 'Purchase Parcel.',
            message: 'Please wait, fetching metadata.',
          })

          // const metadata = selectedParcel.map(data => data.metadata);

          const _mintData = []

          for (let i = 0; i < selectedParcel.length; i++) {
            setMintModalData({
              status: 'PURCHASE',
              title: 'Purchase',
              message: `Fetching: ${i + 1} \\ ${
                selectedParcel.length
              } of the metadata.`,
            })

            const fireParcel = await parcelsRef
              .doc(selectedParcel[i].position)
              .get()

            if (fireParcel.exists) {
              const fireParcelData = fireParcel.data()
              let _tokenData = {
                tokenId: fireParcelData.tokenId,
                tokenURI: fireParcelData.tokenURI,
                price: parseStingToEther(`${fireParcelData.price}`),
                index: fireParcelData.index,
                position: fireParcelData.position,
              }
              _mintData.push(_tokenData)
            }
          }

          setMintModalData({
            status: 'PURCHASE',
            title: 'Purchase Parcels.',
            message: `Please the transaction to complete the Purchase.`,
          })
          const gasLimit = await contract.estimateGas.marketPlaceSale(
            _status,
            _mintData,
            { value, nonce }
          )
          const createSaleTx = await contract.marketPlaceSale(
            _status,
            _mintData,
            {
              gasPrice,
              gasLimit,
              value,
              nonce,
            }
          )

          setMintModalData({
            status: 'PURCHASE',
            title: 'Purchase Parcels.',
            message: ' Transaction sent, awaiting for confirmation.',
          })

          const tx = await createSaleTx.wait()
          if (tx && tx.status === 1) {
            for (let i = 0; i < selectedParcel.length; i++) {
              const data = selectedParcel[i]

              setMintModalData({
                status: 'PURCHASE',
                title: 'Purchase Parcels.',
                message: `Updating: ${i + 1} \\ ${
                  selectedParcel.length
                } of NFT's, please wait.`,
              })

              await parcelsRef.doc(data.position).update(
                {
                  ...selectedParcel[i],
                  sold: true,
                  owner: accounts[0],
                  onAuction: false,
                  updated_at: firebase.firestore.Timestamp.now(),
                },
                { merge: true }
              )
            }
          }

          return tx
        } else if (_status === 'AUCTION') {
          // const _total = selectedParcel.reduce(getSum, 0)

          setMintModalData({
            status: 'AUCTION',
            title: 'Auctioning.',
            message: 'Please wait, fetching metadata.',
          })

          // const metadata = selectedParcel.map(data => data.metadata);

          const _mintData = []

          for (let i = 0; i < selectedParcel.length; i++) {
            setMintModalData({
              status: 'AUCTION',
              title: 'Auctioning.',
              message: `Processing: ${i + 1} \\ ${
                selectedParcel.length
              } of NFT's.`,
            })

            const data = selectedParcel[i]
            const price = ethers.utils.parseEther(`${data.price}`)

            const toSend = {
              tokenId: data.tokenId, // This input can be any uint256 on creation.
              tokenURI: data.tokenURI,
              price,
              index: data.index,
              position: data.position,
            }

            _mintData.push(toSend)
          }

          setMintModalData({
            status: 'AUCTION',
            title: 'Auctioning.',
            message: `Please sign the transaction to complete the Auction.`,
          })
          const gasLimit = await contract.estimateGas.marketPlaceSale(
            _status,
            _mintData,
            { nonce }
          )
          const createSaleTx = await contract.marketPlaceSale(
            _status,
            _mintData,
            {
              gasPrice,
              gasLimit,
              nonce,
            }
          )

          setMintModalData({
            status: 'AUCTION',
            title: 'Auctioning',
            message: 'Transaction sent: awaiting for confirmation.',
          })

          const tx = await createSaleTx.wait()

          for (let i = 0; i < selectedParcel.length; i++) {
            const data = selectedParcel[i]

            setMintModalData({
              status: 'AUCTION',
              title: 'Auctioning.',
              message: `Updating: ${i + 1} \\ ${
                selectedParcel.length
              } of NFT's, please wait.`,
            })

            await parcelsRef.doc(data.position).update(
              {
                ...selectedParcel[i],
                owner: contract.address,
                seller: accounts[0],
                onAuction: true,
                updated_at: firebase.firestore.Timestamp.now(),
              },
              { merge: true }
            )
          }

          setMintModalData({
            status: 'SUCCESS',
            title: 'Transaction Complete.',
            message: 'Successfully auctioned your parcels.',
          })
          setMinting(false)

          return tx
        }
      }
    } catch (err) {
      let errMsg = ''
      console.error(err)

      if (err.code === (-32603 || 'NONCE_EXPIRED')) {
        if (err.error) {
          errMsg = `${
            err?.error.message
              .match(/{"(.*)"}/)
              .pop()
              .match(/:"(.*)",/)
              .pop() || 'Please adjust Nonce amount.'
          }`
        } else {
          errMsg = `${err.message.match(/:"(.*).",/).pop()}`
        }
      } else if (err.code === 'UNPREDICTABLE_GAS_LIMIT') {
        errMsg = `${err?.error.data.message}`
      } else if (err.code === ('ACTION_REJECTED' || 4001)) {
        errMsg =
          'The transactions was cancelled. Please close the modal to try again.'
      } else {
        errMsg = 'Something went wrong while Minting NFT.'
      }
      throw new Error(errMsg)
    }
  }

  return (
    <NFTMarketplaceContext.Provider
      value={{
        isMetamaskInstalled,
        handleChainChanged,
        connectWallet,
        signOut,

        generateMetadata,
        uploadToIPFS,

        checkContract,
        checkAvailability,
        // fetchNFTS,
        fetchMyParcels,
        fetchListedTokens,
        fetchAllTokens,
        // checkParcelsAvailability,
        createMarketPlaceSale,
        parseEtherToString,
        parseStingToEther,
        fetchAccBalance,
      }}
    >
      {children}
    </NFTMarketplaceContext.Provider>
  )
}

import { lazy } from "react";
const AccountPage = lazy(() => import("../pages/AccountPage"));
const LandsPage = lazy(() => import("../pages/LandsPage"));
const AboutPage = lazy(() => import("../pages/AboutPage"));
const Page404 = lazy(() => import("../pages/Page404"));

const routes = [
    {
        path: '/',
        exact: true,
        component: LandsPage,
        name: 'Base Page',
        protected: false,
        element: <LandsPage />
    },
    {
        path: '/account',
        exact: true,
        component: AccountPage,
        name: 'Account Page',
        protected: true,
        element: <AccountPage />
    },
    {
        path: '/about',
        exact: true,
        component: AboutPage,
        name: 'About Page',
        protected: false,
        element: <AboutPage />
    },
    {
        path: '*',
        exact: false,
        component: Page404,
        name: '404 Page',
        protected: false,
        element: <Page404 />
    },
];

export default routes;
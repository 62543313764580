
export const truncateAddress = (input, len) =>
    (input) ? input.length > len ? `${input.substring(0, len)}...` : input : 'null';

export const getRandomFloat = (min, max, decimals) => {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    return parseFloat(str);
}

export const fetchNetworkDetails = (_chainId) => {
    if (_chainId === 1) {
        return {
            name: "ethereum",
            etherscanUrl: 'https://etherscan.io'
        };
    } else if (_chainId === 3) {
        return {
            name: "ropsten",
            etherscanUrl: 'https://ropsten.etherscan.io'
        };
    } else if (_chainId === 4) {
        return {
            name: "rinkeby",
            etherscanUrl: 'https://rinkeby.etherscan.io'
        };
    } else if (_chainId === 42) {
        return {
            name: "kovan",
            etherscanUrl: 'https://kovan.etherscan.io'
        };
    } else if (_chainId === 5) {
        return {
            name: "goerli",
            etherscanUrl: 'https://goerli.etherscan.io'
        };
    } else if (_chainId === 31337) {
        return {
            name: "localhost",
            etherscanUrl: 'https://localhost.etherscan.io'
        };
    } else {
        return {
            name: "ethereum",
            etherscanUrl: 'https://etherscan.io'
        };
    }

}

// import nftMarketplace from "./MillionTokenMarketplace.json"; // File location: ../../artifacts/contracts/MillionTokenMarketplace.sol/MillionTokenMarketplace.json

export const NFTMarketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
// export const NFTMarketplaceABI = nftMarketplace.abi;
// export const NFTMarketplaceABI = nftMarketplace;

export const NFTMarketplaceABI = [
    // MarketItem - tuple(uint256 tokenId, uint256 index, string position, address seller, address owner, uint256 price, bool onAuction, bool sold)
    // TokensData - tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)
    // TokenUriData - tuple(uint256 tokenId, string tokenURI)
    "function getListingPrice() view returns (uint256)",
    "function fetchAllTokens() public view returns (tuple(uint256 tokenId, uint256 index, string position, address seller, address owner, uint256 price, bool onAuction, bool sold)[] memory)",
    "function fetchListedTokens() public view returns (tuple(uint256 tokenId, uint256 index, string position, address seller, address owner, uint256 price, bool onAuction, bool sold)[] memory)",
    "function fetchMyTokens() public view returns (tuple(uint256 tokenId, uint256 index,string position, address seller, address owner, uint256 price,bool onAuction,bool sold)[] memory)",
    // "function fetchMyListedTokens() public view returns (tuple(uint256 tokenId, uint256 index, string position, address seller, address owner, uint256 price, bool sold)[] memory)",
    "function checkTokenAvailability( string _status, uint256[] _tokensData) public view returns(bool)",

    // "function createToken(string tokenURI,uint256 price) public payable returns (uint256)",
    // "function marketPlaceSale(bool resale, TokensData[] tokensData) public payable returns(TokensData[])",
    "function marketPlaceSale(string _status, tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] tokensData) public payable returns(tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] memory)",
    // "function createMarketSale(tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] tokensData) public payable returns(tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] memory)",
    // "function updateTokenUri(tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] _tokenUriData) public returns(tuple(uint256 tokenId, string tokenURI, uint256 index,string position,uint256 price)[] memory)",
    // "function reSellToken(uint256 tokenId, uint256 price)",
    // "function fetchMarketItem() view returns (MarketItem[])",
]
import firebase from 'firebase/app'

const config = {
apiKey: process.env.REACT_APP_FIREBASE_KEY,
authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,

    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
appId: process.env.REACT_APP_FIREBASE_APP_ID,
measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

}

const app = firebase.initializeApp(config);

// export const auth = app.auth().useEmulator('http://127.0.0.1:9099')
// export const firestore = app.firestore().useEmulator('http://127.0.0.1:8080')
// export const storage = app.storage().useEmulator('http://127.0.0.1:9199')
// export const functions = app
//   .functions()
//   .useEmulator('http://127.0.0.1:5001')

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()

export const functions = app.functions()


export const analytics = app.analytics();
export default app;


export const AnonymousSignIn = () =>
    new Promise((resolve, reject) => {
        auth.signInAnonymously()
            .then(result => resolve(result))
            .catch(error => reject(error));
    });

export const fireSignOut = () => {
    return firebase
        .auth()
        .signOut()
};

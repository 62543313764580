import './styles/App.scss';
import React, { Suspense } from 'react'

import { PersistGate } from 'zustand-persist'
import routes from './modules/routes'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import BasePage from './pages/BasePage'
import { NFTMarketplaceProvider } from './context/NFTMarketplaceContext'

const App = () => {
  return (
    <PersistGate>
      <BrowserRouter>
        <NFTMarketplaceProvider>
          <Suspense
            // fallback={<Loading title={'Loading...'} message={'Please wait.'} />}
            fallback={<div className="main-page-loader-wrapper">&nbsp;</div>}
          >
            <Routes>
              <Route path="/" element={<BasePage />}>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={route.element}
                  />
                ))}
              </Route>
            </Routes>
          </Suspense>
        </NFTMarketplaceProvider>
      </BrowserRouter>
      <ToastContainer />
    </PersistGate>
  )
}

export default App;

import createStore from 'zustand';
import persist from '../utils/persist';
export const useStore = createStore(
    persist(
        {
            key: 'milliontoken-storage',
            denylist: ['minting', 'authenticating', 'balance', 'mintModalData', 'total,'
            ]
        },
        (set) => ({
            accounts: [],
            setAccounts: (val) => set(state => ({ accounts: val || [] })),

            balance: "0.0",
            setBalance: (val) => set(state => ({ balance: val || '0.0' })),

            fireUser: [],
            setFireUser: (val) => set(state => ({ fireUser: val || [] })),

            fireAccount: [],
            setFireAccount: (val) => set(state => ({ fireAccount: val || [] })),

            chainId: Number(process.env.REACT_APP_NETWORK_ID),
            setChainId: (val) => set(state => ({ chainId: val || Number(process.env.REACT_APP_NETWORK_ID) })),

            signer: [],
            setSigner: (val) => set(state => ({ signer: val || [] })),

            authenticating: false,
            setAuthenticating: (val) => set(state => ({ authenticating: val })),

            minting: false,
            setMinting: (val) => set(state => ({ minting: val })),

            mintModalData: {
                status: "MINTING", // SUCCESS || ERROR
                title: "Minting Parcels",
                message: "Please wait as your transaction is being processed."
            },
            setMintModalData: (val) => set(state => ({ mintModalData: val || null })),

            total: 0.0015, // change to token_price
            setTotal: (val) => set(state => ({ total: val })),

            selectedParcel: [],
            setSelectedParcels: (val) => set(state => ({ selectedParcel: val || null })),

            // selectedCollection: {
            //     collection: [],
            //     parcels: [],
            //     canAuction: false
            // },
            selectedCollection: null,
            setSelectedCollection: (val) => set(state => ({ selectedCollection: val || null })),

            mapIsMultipleSelect: false,
            setMapIsMultipleSelect: (val) => set(state => ({ mapIsMultipleSelect: val || false })),

        })
    )
);


import '../styles/Base.scss';
import { Outlet } from "react-router-dom";

const BasePage = () => {

    return (

        <div className="base-page-wrapper">
            <div className="main-body-wrapper">
                <Outlet />
            </div>

        </div>

    );
}

export default BasePage;
